import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import experianceBG from "../assets/experianceBG.png"
import { CheckCircle } from "@mui/icons-material";
import monitors from "../assets/monitors.png"

const FeatureDeliver = () => {
    return (
        <Stack sx={{
            width: "100%",
            height: { xs: 650, md: "375px" },
            background: `url(${experianceBG})`,
            backgroundSize: "cover",
        }} direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"space-evenly"} p={3}>

            <Stack gap={3}>
                <Typography variant='h4' fontWeight={"700"}>Experience Excellence With StackArch</Typography>
                <Stack gap={1}>
                    <Typography variant='body1'> <CheckCircle />Comprehensive Project Management</Typography>
                    <Typography> <CheckCircle />Enhance Efficiency and Productivity</Typography>
                    <Typography> <CheckCircle />User-Friendly Dashboard</Typography>
                </Stack>
            </Stack>

            <Stack>
                <Box component={"img"} src={monitors} alt='monitor' maxWidth={{ xs: "500px", md: "700px" }} />
            </Stack>

        </Stack>
    )
}

export default FeatureDeliver