import React from 'react'

import { Disclosure } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo/stacklogo.webp";

function Navbar() {
    const location = useLocation();
    const checkPath = (path) => {
        return location.pathname.split("/")[1] === path;
    };

    return (
        <>
            <div className="w-full  top-0 z-50 fixed"
            >
                <nav
                    className={
                        // !check
                        "container max-w-full  absolute flex flex-wrap items-center justify-between p-2 mx-auto lg:justify-between xl:px-10 "
                        // : "container max-w-full  relative flex flex-wrap items-center justify-between p-2 mx-auto lg:justify-between xl:px-10 "
                    }
                    style={{ "backgroundColor": "rgba(19, 19, 49, 1)" }}
                >
                    {/* Logo  */}
                    <Disclosure>
                        {({ open, close }) => (
                            <>
                                <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
                                    <Link to="/">
                                        <a href='/' className="flex items-center space-x-2 text-2xl font-medium text-[#0057A9] dark:text-gray-100">
                                            <span>
                                                <img src={Logo} alt="N" className="h-[75px] w-[265px]" />
                                            </span>
                                            {/* <span></span> */}
                                        </a>
                                    </Link>

                                    <Disclosure.Button
                                        aria-label="Toggle Menu"
                                        className="px-2 py-1 ml-auto text-white  rounded-md lg:hidden hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white focus:outline-none dark:text-gray-300 dark:focus:bg-trueGray-700"
                                    >
                                        <svg
                                            className="w-6 h-6 fill-current"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                        >
                                            {open && (
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                                                />
                                            )}
                                            {!open && (
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                                                />
                                            )}
                                        </svg>
                                    </Disclosure.Button>

                                    <Disclosure.Panel className="flex flex-wrap w-full my-5  lg:hidden rounded"
                                        style={{ "backgroundColor": "rgba(19, 19, 49, 0.5)" }}
                                    >
                                        <>
                                            <Link
                                                to="/"
                                                className="w-full px-8 py-2   rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                                                style={{
                                                    background: checkPath("") ? "white" : "",
                                                    color: checkPath("") ? "#0057A9" : "",
                                                }}
                                                onClick={close}
                                            >
                                                Home
                                            </Link>
                                            <Link
                                                to="/features"
                                                className="w-full px-8 py-2   rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                                                onClick={close}
                                                style={{
                                                    background: checkPath("features") ? "white" : "",
                                                    color: checkPath("features") ? "#0057A9" : "",
                                                }}
                                            >
                                                Features
                                            </Link>

                                            <Link
                                                to="/blogs"
                                                className="w-full px-8 py-2   rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                                                onClick={close}
                                                style={{
                                                    background: checkPath("blogs") ? "white" : "",
                                                    color: checkPath("blogs") ? "#0057A9" : "",
                                                }}
                                            >
                                                Blogs
                                            </Link>
                                            <Link
                                                to="/contact"
                                                className="w-full px-8 py-2   rounded-md dark:text-gray-300  text-white hover:text-[#0057A9] focus:text-[#0057A9] focus:bg-white dark:focus:bg-gray-800 focus:outline-none"
                                                onClick={close}
                                                style={{
                                                    background: checkPath("contact") ? "white" : "",
                                                    color: checkPath("contact") ? "#0057A9" : "",
                                                }}
                                            >
                                                Contact
                                            </Link>
                                        </>
                                    </Disclosure.Panel>
                                </div>
                            </>
                        )}
                    </Disclosure>

                    {/* menu  */}
                    <div className="hidden text-center lg:flex lg:items-end">


                        <ul className="items-center justify-end flex-1 gap-2 pt-6 list-none lg:pt-0 lg:flex">

                            <Link
                                to="/"
                                className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none "

                            >
                                <div style={{
                                    borderBottom: checkPath("") ? "3px solid #009ADE" : "",
                                    color: checkPath("") ? "#009ADE" : "",
                                    paddingBottom: "12px"
                                }}>
                                    Home
                                </div>
                            </Link>
                            <Link
                                to="/features"
                                className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none"
                            >
                                <div style={{
                                    borderBottom: checkPath("features") ? "3px solid #009ADE" : "",
                                    color: checkPath("features") ? "#009ADE" : "",
                                    paddingBottom: "12px"
                                }}>
                                    Features
                                </div>
                            </Link>

                            <Link
                                to="/blogs"
                                className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none"

                            >
                                <div style={{
                                    borderBottom: checkPath("blogs") ? "3px solid #009ADE" : "",
                                    color: checkPath("blogs") ? "#009ADE" : "inherit",
                                    paddingBottom: "12px"
                                }}>

                                    Blogs
                                </div>
                            </Link>
                            <Link
                                to="/contact"
                                className="inline-block px-4 py-2 text-lg font-normal text-white no-underline dark:text-gray-200 hover:text-[#009ADE] focus:text-[#009ADE]  focus:outline-none"
                            >
                                <div style={{
                                    borderBottom: checkPath("contact") ? "3px solid #009ADE" : "",
                                    color: checkPath("contact") ? "#009ADE" : "",
                                    paddingBottom: "12px"
                                }}>
                                    Contact us
                                </div>
                            </Link>
                        </ul>
                    </div>

                    <div className="hidden mr-3 space-x-4 lg:flex nav__item">
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar