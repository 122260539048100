import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import architech from "../assets/building.webp"
function FeatureDescription() {
    return (
        <>
            <div
                className="hero_about_bg"
                style={{
                    // backgroundImage: `url(${aboutLink})`,
                    background: `url(${architech})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundBlendMode: "hard-light",


                }}
            >
                <Container maxWidth="xl">
                    <Grid
                        container
                        padding="60px 2px 60px 20px"
                        alignItems={"center"}
                        spacing={2}
                    >
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ flex: 1 }}>
                                <Typography
                                    variant="h4"
                                    className="text-white"
                                    fontWeight={{ xs: "600", md: "bold" }}
                                    fontSize={{ xs: "28px", md: 32 }}
                                    gutterBottom
                                >
                                    Why to choose StackArch ?
                                </Typography>
                                <Typography variant="body1" className="text-white" fontSize={{ xs: "16px", md: "18px" }}>
                                    StackArch simplifies complex processes involved in architectural project management, helping architects stay organized and focused on delivering exceptional results. By integrating all key project management functions into a single platform, architects can save time, reduce errors, and improve overall project efficiency. The web and mobile platforms of StackArch enable architects to access and manage their projects, ensuring flexibility and real-time updates. Whether working from the office or on-site, architects can stay connected, monitor project progress, make updates, and communicate with the team & clients easily.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default FeatureDescription