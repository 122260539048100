import { Routes, Route } from "react-router-dom"
import HomePage from "./pages/HomePage";
import Navbar from "./components/Navbar"
import Footer from "./components/Footer";
import FeaturesPage from "./pages/FeaturesPage";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import BlogsPage from "./pages/BlogsPage";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import stackLogo from "./assets/logo/stacklogo.webp"

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import BlogDetail from "./components/blogs/blogDetail";
import CallUs from "./components/CallUs";

function App() {
  return (
    <div>
      <ScrollToTop>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs/blogdetail" element={<BlogDetail />} />
        </Routes>

        <Footer />
      </ScrollToTop>
      <WhatsAppWidget
        phoneNo="919072863636"
        position="right"
        // widgetWidth="300px" 
        widgetWidthMobile="260px"
        // autoOpen={true}
        // autoOpenTimer={1000}
        messageBox={true}
        // messageBoxTxt="Hi Team, is there any related service available ?"
        iconSize="56"
        iconColor="white"
        iconBgColor="rgb(79, 206, 93)"
        // headerIcon={stackLogo}
        // headerIconColor="pink"
        // headerTxtColor="black"
        // headerBgColor="tomato"
        headerTitle="StackArch"
        headerCaption="Online"
        // bodyBgColor="#bbb"
        chatPersonName="Support"
        chatMessage={<>Hi there 👋 <br /> How we can help you?</>}
        // footerBgColor="#999"
        placeholder="Type a message.."
        btnBgColor="#075E54"
      />
      <CallUs />
    </div>
  );
}

export default App;
