import {
    Stack,
    Box,
    Typography,
    Button,
    Card,
    CardContent,
} from "@mui/material";
import React from "react";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { AccessTime, CalendarMonth } from "@mui/icons-material";

const RecentBlogCard = ({ data }) => {
    const navigate = useNavigate();

    return (
        <div
            onClick={() => navigate(`/blogs/blogdetail?id=${data._id}`)}
            sx={{
                marginTop: "20px",
                backgroundImage:
                    "linear-gradient(180deg, #0B0D23 83.33%, #242242 100%)",
            }}
        >
            <CardContent>
                <Stack direction="row" spacing={2} alignItems={"center"}  >

                    <Box width={{ sm: "300px" }}>
                        <img
                            src={data?.images[0]}
                            // height="150px" 
                            style={{ cursor: "pointer", objectFit: "cover" }}
                        />
                    </Box>
                    <Stack color="white" width={{ xs: "150px", md: "auto", sm: "250px" }}>
                        <Box>
                            <Typography
                                // variant="h6"
                                fontWeight="600"
                                fontSize={{ sm: "16px", md: "17px", xs: "14px" }}
                                sx={{
                                    cursor: "pointer",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "normal",
                                    width: { xs: "150px", md: "200px", sm: "300px" },
                                    display: "-webkit-box",
                                    "-webkit-line-clamp": "2",
                                    "-webkit-box-orient": "vertical",
                                }}
                            >
                                {data.title}
                            </Typography>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontSize="15px"
                                sx={{
                                    cursor: "pointer",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    width: "200px",
                                }}
                            >
                                {data.description}
                            </Typography>
                            <Typography variant="caption"><CalendarMonth fontSize="small" />&nbsp;
                                {Moment(data.createdAt).fromNow()}
                            </Typography>
                            <br></br>
                            <Typography variant="caption"><AccessTime fontSize="small" />&nbsp;{data.readingTime} min read</Typography>
                        </Box>
                    </Stack>
                </Stack>
            </CardContent>
        </div>
    );
};

export default RecentBlogCard;
