import { Stack } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import ContactUs from '../components/ContactUs'
import ContactForm from '../components/ContactForm'

function Contact() {
    return (
        <>

            <Helmet>
                <title>StackArch | Contact Us</title>
            </Helmet>
            <Stack pt={{ xs: 15, md: 14 }} sx={{
                background: "rgba(19, 19, 49)",
                opacity: "1",
                width: "100%"
            }} spacing={5}>
                <ContactUs />
                <ContactForm />
            </Stack>
        </>
    )
}

export default Contact