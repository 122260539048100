import { Box, Stack, Typography } from '@mui/material'

import taskImg from "../assets/taskImg.webp"
import delivery from "../assets/delivry.webp"
import taskLevel from "../assets/tasklevel.webp"
import rectImg from "../assets/rectImg.webp"
import circImg from "../assets/circImg.webp"
import planningSchedule from "../assets/the planning schedule.webp"

import React from 'react'
import "../index.css"
function HowITWorks() {
    return (
        <>
            <Stack justifyContent="center" alignItems={"center"} p={{ xs: 2 }}>
                <Box alignItems={"center"} maxWidth={"467px"} sx={{ display: "flex", flexDirection: "column", gap: "20px" }} >
                    <Typography
                        sx={
                            { "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "28px", md: "48px" }, "lineHeight": { xs: "39px", md: "56px" }, "color": "#FFFFFF" }
                        }
                    > How does it Works?
                    </Typography>
                    <Typography
                        sx={{ "fontStyle": "normal", "fontWeight": "400", "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "23px", "textAlign": "center", "color": "#FFFFFF" }}
                    >
                        Make your work easier with an integrated ecosystem that lets all departments work properly together.
                    </Typography>
                </Box>

                <Stack mt={5} direction={{ xs: "column", sm: "column", md: "row" }} flexWrap={"wrap"} justifyContent={"center"} gap={10} alignItems={"center"} >

                    <Box p={2} component={"div"} sx={{ "width": { xs: "340px", md: "380px" }, "height": "400px", "background": "#D9D9D9", "borderRadius": "10px", display: "flex", flexDirection: "column", alignItems: "center", gap: "25px", justifyContent: "center" }}>
                        <Box component={"img"} alt='task image' src={taskImg} />
                        <Typography sx={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "textAlign": "center", "color": "#000000" }}>
                            Task Management</Typography>
                        <Typography sx={{ "fontStyle": "normal", "fontWeight": "400", "fontSize": { xs: "16px", md: "18px" }, "lineHeight": "23px", "textAlign": "center", "color": "#000000" }}>
                            StackArch ERP consists of task wise listing in project modules that help architects to monitor each task completion related to that project.</Typography>
                    </Box>

                    <Box p={2} component={"div"} sx={{ "width": { xs: "340px", md: "380px" }, "height": "400px", "background": "#D9D9D9", "borderRadius": "10px", display: "flex", flexDirection: "column", alignItems: "center", gap: "25px", justifyContent: "center" }}>
                        <Box component={"img"} alt='task level' src={taskLevel} />
                        <Typography sx={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "textAlign": "center", "color": "#000000" }}>
                            Task Level Communication</Typography>
                        <Typography sx={{ "fontStyle": "normal", "fontWeight": "400", "fontSize": { xs: "16px", md: "18px" }, "lineHeight": "23px", "textAlign": "center", "color": "#000000" }}>
                            Control over each task status update can help the team to understand each task status under the projects. Each project forecasting can help with the help of retrieved data from the system.</Typography>
                    </Box>

                    <Box p={2} component={"div"} sx={{ "width": { xs: "340px", md: "380px" }, "height": "400px", "background": "#D9D9D9", "borderRadius": "10px", display: "flex", flexDirection: "column", alignItems: "center", gap: "25px", justifyContent: "center" }}>
                        <Box component={"img"} alt='delivery' src={delivery} />
                        <Typography sx={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "textAlign": "center", "color": "#000000" }}>
                            On Time Delivery</Typography>
                        <Typography sx={{ "fontStyle": "normal", "fontWeight": "400", "fontSize": { xs: "16px", md: "18px" }, "lineHeight": "23px", "textAlign": "center", "color": "#000000" }}>
                            StackArch architectural ERP comes up with hierarchical control of each project, Managerial module control over each project management</Typography>
                    </Box>

                </Stack>

                <Stack mt={12} direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 10 }} alignItems="center">
                    <Box component={"img"} alt='planning schedule' src={planningSchedule} width={{ xs: "100%", md: "50%" }} />
                    <Stack spacing={4}>
                        <Typography sx={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "28px", md: "48px" }, "lineHeight": { xs: "39px", md: "56px" }, "color": "#FFFFFF", textAlign: { xs: "center", md: "left" } }}>The Planning & Scheduling</Typography>

                        <Box mt={2}>
                            <Stack direction={"row"} alignItems="center" gap={2}>
                                <Box component={"img"} alt='react' src={rectImg} width={"55px"} height={"55px"} />
                                <Stack maxWidth="402px" spacing={2}>
                                    <Typography sx={{ "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "28px", md: "36px" }, "lineHeight": { xs: "39px", md: "42px" }, "color": "#FFFFFF" }}>
                                        Plan</Typography>
                                    <Typography sx={{ "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "23px", "color": "#FFFFFF" }}>In planning phase, plan is made and strategies are set, taking into consideration the company policies, procedures and rules Planning provides direction.</Typography>
                                </Stack>

                            </Stack>
                        </Box>
                        <Box mt={2}>
                            <Stack direction={"row"} alignItems="center" gap={2}>
                                <Box component={"img"} alt='circle image' src={circImg} width={"55px"} height={"55px"} />
                                <Stack maxWidth="402px" spacing={2}>
                                    <Typography sx={{ "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "28px", md: "36px" }, "lineHeight": "42px", "color": "#FFFFFF" }}>
                                        Track</Typography>
                                    <Typography sx={{ "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "23px", "color": "#FFFFFF" }}>StackArck automates project creation by automatically capturing data from the admin & user. </Typography>
                                </Stack>


                            </Stack>
                        </Box>
                    </Stack>
                </Stack>

            </Stack>
        </>
    )
}

export default HowITWorks