import { Box, Stack, Typography } from '@mui/material'
import BannerImg from "../assets/homeBanner.webp"
import tab from "../assets/tab.webp"
import React, { useState } from 'react'
import "../index.css"
import { useNavigate } from 'react-router-dom'
import ReactTyped from "react-typed"

function HomeBanner() {
    const navigate = useNavigate();
    const [showText, setShowText] = useState(false);

    const handleShowText = () => {
        setShowText(true)
    }

    return (
        <Stack direction={{ xs: "column", md: "row" }} justifyContent={'space-between'} alignItems={"center"} p={{ xs: 2, md: 12 }}

            sx={{
                background: `url(${BannerImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                // height: "90vh",
            }}>
            <Stack>
                <Box maxWidth={"650px"} height={{ xs: "90vh", md: "550px" }} sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <Typography mt={{ xs: 6, md: 0 }}
                        sx={{ "fontSize": { xs: "28px", md: "40px", lg: "50px" }, "fontWeight": "700", "lineHeight": { xs: "35px", md: "40px", lg: "60px" }, "letterSpacing": "0em", "textAlign": "left", color: "white" }}>

                        <ReactTyped
                            onComplete={handleShowText}
                            loop={false}
                            typeSpeed={30}
                            strings={["STACKARCH <br />A Complete Project Management Solution For Architects."]}
                            shuffle={false}
                            backDelay={1}
                            fadeOut={false}
                            fadeOutDelay={100}
                            loopCount={0}
                            showCursor
                            cursorChar="|"
                        />
                    </Typography>

                    <Stack sx={{ display: { xs: "block", md: "none" } }} alignItems={"center"}>
                        <Box component={"img"}
                            src={tab}
                            alt='tablet'
                        />
                    </Stack>
                    {
                        showText &&
                        <Typography
                            sx={{
                                "fontStyle": "normal",
                                "fontWeight": "400",
                                "fontSize": { xs: "18px", md: "20px" },
                                "lineHeight": "140.19%"
                                , "color": "#FFFFFF",
                            }}
                        >
                            <ReactTyped
                                loop={false}
                                typeSpeed={30}
                                strings={[" StackArch is the game-changing project management solution designed specifically for architects. StackArch empowers architectural teams to manage projects, workflows, and task tracking with unparalleled efficiency and clarity. With its innovative approach, StackArch offers a comprehensive suite of features tailored to the unique needs of architects, ensuring seamless collaboration, streamlined resource allocation, and detailed project tracking."]}
                                shuffle={false}
                                backDelay={1}
                                fadeOut={false}
                                fadeOutDelay={100}
                                loopCount={0}
                                showCursor
                                cursorChar="|"
                            />
                        </Typography>
                    }

                    {showText &&
                        <Box component={"button"} onClick={() => navigate("/features")}
                            sx={{ "width": { xs: "150px", md: "200px" }, "height": { xs: "45px", md: "55px" }, "left": "120px", "top": "727px", "background": "linear-gradient(180deg, #009ADE 0%, #0E4CA1 100%)", "borderRadius": "10px", "fontStyle": "normal", "fontWeight": "500", "fontSize": "22px", "lineHeight": "28px", "color": "#FFFFFF" }}
                        >Learn More</Box>
                    }
                </Box>
            </Stack>
            <Stack sx={{ display: { xs: "none", md: "block" } }} alignItems={"center"}>
                <Box component={"img"}
                    alt='tab'
                    src={tab}
                />
            </Stack>
        </Stack>
    )
}

export default HomeBanner