import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import webView from "../assets/webveiw.webp"
import headset from "../assets/headset.webp"
import chat from "../assets/chat.webp"
import easy from "../assets/easy.webp"
import copy from "../assets/copy.webp"
function Features() {
    return (
        <Stack direction={{ xs: "column", md: "row" }} mt={4} alignItems={"center"} justifyContent={"center"}>
            <Stack width={{ xs: '100%', sm: "80%", md: "60%" }} alignItems={'center !important'} >
                <Typography sx={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "28px", md: "48px" }, "lineHeight": { xs: "39px", md: "56px" }, "color": "#FFFFFF", textAlign: { xs: "center", md: "left" } }}
                >
                    Features Of STACKARCH.</Typography>
                <Stack sx={{ display: { xs: "block", md: "none" } }} >
                    <Box component={"img"} alt='web view' src={webView} width={{ xs: "100%", sm: "60%" }} m="0 auto" />
                </Stack>
                <Stack direction={"row"} mt={{ xs: 4, md: 10 }} flexWrap={"wrap"} maxWidth={"550px"} justifyContent={{ xs: "center", md: "space-between" }} alignItems={"center"}>
                    <Stack direction={"row"} spacing={2} width={"250px"} alignItems={"center"}>
                        <Box component={"img"} alt='headset' src={headset} width={"65px"} height={"65px"} />
                        <Stack >
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "24px", md: "32px" }, "lineHeight": "38px", "color": "#FFFFFF" }}>
                                Support</Typography>

                        </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} width={"250px"} mt={{ xs: 2, md: 0 }} alignItems={"center"}>
                        <Box component={"img"} alt='chat' src={chat} width={"65px"} height={"65px"} />
                        <Stack >
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "24px", md: "32px" }, "lineHeight": "38px", "color": "#FFFFFF" }}>
                                Chat</Typography>

                        </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} width={"250px"} mt={4} alignItems={"center"}>
                        <Box component={"img"} alt='copy' src={copy} width={"65px"} height={"65px"} />
                        <Stack >
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "24px", md: "32px" }, "lineHeight": "38px", "color": "#FFFFFF" }}>
                                Management</Typography>

                        </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2} width={"250px"} mt={4} alignItems={"center"}>
                        <Box component={"img"} alt='easy' src={easy} width={"65px"} height={"65px"} />
                        <Stack >
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "24px", md: "32px" }, "lineHeight": "38px", "color": "#FFFFFF" }}>
                                Easy To Use</Typography>

                        </Stack>
                    </Stack>

                </Stack>
            </Stack>
            <Stack sx={{ display: { xs: "none", md: "block" } }} width={"50%"}>
                <Box component={"img"} alt='web view' src={webView} width={"100%"} />
            </Stack>
        </Stack>
    )
}

export default Features