import React from 'react'
import { Helmet } from 'react-helmet'
import BlogSliderSection from '../components/blogs/BlogSliderSection'
import ContactForm from '../components/ContactForm'

function BlogsPage() {
    return (
        <>
            <Helmet>
                <title>StackArch | Blogs</title>
            </Helmet>
            <BlogSliderSection />
            <ContactForm />

        </>
    )
}

export default BlogsPage