import { Box, Card, CardHeader, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RecentBlogCard from "./RecentBlogCard";

const RecentBlog = () => {
    const [related, setRelated] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/blog/user/latest?size=5&category=stackarch`)
            .then((res) => {
                setRelated(res.data.data.blogPosts);
                console.log(setRelated);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return (
        <Paper
            elevation={3}
            sx={{
                padding: "10px",
                marginTop: "20px",
                // maxHeight: { md: "790px", xs: "unset", sm: "unset", lg: "790px" },
                // overflowY: { xs: "none", sm: "none", md: "none" },
                backgroundImage:
                    "linear-gradient(180deg, #0B0D23 83.33%, #242242 100%)",
            }}
        >
            <Box sx={{ marginBottom: "10px" }}>
                <Typography variant="h5" fontWeight={"bold"} color="white">
                    Recommended Reads
                </Typography>
            </Box>
            {related.map((e, index) => (
                <>
                    <Stack spacing={2}>
                        <RecentBlogCard data={e} />
                    </Stack>
                    {related.length - 1 !== index && <hr />}
                </>
            ))}
            {related.length === 0 && (
                <h1 className="text-white align-middle">Nothing to show</h1>
            )}
        </Paper>
    );
};

export default RecentBlog;
