import React from "react";
import {
    Box,
    Stack,
    Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import phone from "../assets/phone.webp";

import phoneIcon from "../assets/phoneIcon.webp"
import mail from "../assets/email.webp"
import location from "../assets/location.webp"

const ContactUs = () => {
    const data = [
        {
            locationTitle: "Govt. Cyberpark",
            location: "Ground Floor, 28/1650D, KSUM MOBILE 10X, GOVT. CYBERPARK Nellikode PO, Kozhikode-673016",
            phone: "+ 91 8848 905 165,+ 91 9072 863 636",
            email: "info@stackroots.in,hr@stackroots.in"
        },
        {
            locationTitle: "Calicut",
            location: "#407, 4th Floor, Indo Arcade, Cherooty Road, Kozhikode,Kerala,India, 673032,",
            phone: "+ 91 8848 905 165,+ 91 9072 863 636",
            email: "info@stackroots.in,hr@stackroots.in"
        },
        {
            locationTitle: "UL CyberPark",
            location: "Kerala Startup Mission, 2nd Floor, UL CyberPark Rd, Nellikkode, Kozhikode, Kerala 673008",
            phone: "+ 91 8848 905 165,+ 91 9072 863 636",
            email: "info@stackroots.in,hr@stackroots.in"
        },
        {
            locationTitle: "UK",
            location: "London 34, Newstreet, CM1 1PH, Chelmsford United Kingdom +44-7733646495",
            phone: "+44-7733646495",
            email: "info@stackroots.in,hr@stackroots.in"
        },
    ]
    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "space-around",
                        display: "flex",
                        flexDirection: { xs: "column", md: "row", sm: "row" },
                    }}
                >
                    <Box
                        sx={{
                            flexDirection: "column",
                            marginTop: { xs: "10px", sm: "0px", md: "0px" },
                            width: { xs: "100%", md: "50%" }
                        }}
                    >
                        <Typography
                            variant="h2"
                            fontWeight="bold"
                            textAlign="left"
                            sx={{ fontSize: { xs: 28, md: 34 }, color: "white" }}
                        >
                            Elavate your architecture practice with
                        </Typography>
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            textAlign="left"
                            sx={{ fontSize: { xs: 28, md: 34 }, color: "white" }}
                        >
                            StackArch - The ultimate project management solution
                        </Typography>

                        <Typography
                            variant="h4"
                            fontSize="18px"
                            fontWeight="medium"
                            marginTop="10px"
                            textAlign="left"
                            color={"white"}
                            maxWidth={"650px"}
                        >
                            StackArch is an all-in-one project management ERP software for architectural firms developed by Stackroots. Leveraging the capabilities of StackArch , architects can improve the efficiency, productivity, and competitiveness of the organization. StackArch simplifies the entire project management process, making it easier than ever for architects to take on more projects and manage the workflows.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            padding: "50px",
                            display: { xs: "flex", sm: "flex", md: "flex" },
                        }}
                    >
                        <img src={phone} alt="banner" width="550px" />
                    </Box>
                </Box>
            </Box>

            <Box>
                <Typography
                    variant="h2"
                    fontWeight="bold"
                    textAlign="center"
                    lineHeight="60px"
                    sx={{ fontSize: { xs: 32, md: 34 }, color: "white" }}
                >
                    Get In Touch
                </Typography>

                <Stack mt={4}>
                    <Stack direction={"row"} flexWrap={"wrap"} gap={5} justifyContent={"center"}>
                        {
                            data.map((item) => (


                                <Stack color="black"
                                    sx={{ "width": "301px", "height": "308px", "background": "#FFFFFF", borderTop: "14px solid #1C6CEB" }}
                                    p={2}
                                    gap={2}
                                    alignItems={"flex-start"}
                                    justifyContent={"center"}
                                >
                                    <Stack direction={"row"} alignItems={"flex-start"} gap={2}>
                                        <Box component={"img"} alt="location" src={location} width="15px" mt={1.5} />
                                        <Stack>
                                            <Typography sx={{ "fontWeight": "400", "fontSize": "22px", "lineHeight": "37px" }} >{item.locationTitle}</Typography>
                                            <Stack>
                                                <Typography sx={{ fontSize: "15px", fontWeight: "400" }}>
                                                    {item.location}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    <Stack direction={"row"} alignItems={"flex-start"} gap={2} height={"45px"} >
                                        <Box component={"img"} alt="phone icon" src={phoneIcon} width="15px" mt={1} />
                                        <Stack>
                                            {item.phone?.split(",")?.map((mobile) => (
                                                <Typography>{mobile}</Typography>
                                            ))
                                            }
                                        </Stack>
                                    </Stack>

                                    <Stack direction={"row"} alignItems={"flex-start"} gap={2}>
                                        <Box component={"img"} alt="mail" src={mail} width="15px" mt={1} />
                                        <Stack>
                                            <Typography>info@stackroots.in</Typography>
                                            <Typography>hr@stackroots.in</Typography>
                                        </Stack>
                                    </Stack>

                                </Stack>
                            ))
                        }
                    </Stack>
                </Stack>


            </Box>
        </Container>
    );
};

export default ContactUs;
