import { LoadingButton } from "@mui/lab";
import { Button, styled } from "@mui/material";
import React from "react";

const StyledButton = styled(Button)(({ theme }) => ({
    background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important",
    padding: "5px 20px",
    color: "white",
    borderRadius: "10px",
    "&:hover": {
        background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important",
    },
}));
const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important",
    padding: "5px 20px",
    borderRadius: "10px",
    color: "white",
    "&:hover": {
        background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important",
    },
}));

export function CustomButton(props) {
    return <StyledButton {...props}>{props.children}</StyledButton>;
}

export function CustomLoadingButton(props) {
    return <StyledLoadingButton {...props}>{props.children}</StyledLoadingButton>;
}
