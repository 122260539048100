import { Container, Stack, Typography } from '@mui/material'
import React from 'react'

import easy from "../assets/easetouse.webp"
import team from "../assets/team.webp"
import chat from "../assets/chat1.webp"
import cloud from "../assets/cloudUse.webp"
import plan from "../assets/planning.webp"
import manage from "../assets/management.webp"
import standard from "../assets/std.webp"
import low from "../assets/costlower.webp"
import collab from "../assets/collabrate.webp"

function FeaturesCards() {
    return (
        <>
            <Typography
                textAlign="center"
                variant="h6"
                fontWeight="bold"
                sx={{
                    paddingTop: "30px",
                    color: "#ffff",
                    fontSize: { xs: 20, md: 32, sm: 32 },
                }}
            >
                Features of StackArch
            </Typography>
            <Container maxWidth="xl" >
                <Stack spacing={{ md: "100px", xs: "50px" }} mt={2}>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex flex-1 items-center w-full lg:w-1/2 ">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 20, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    Easy to use
                                </Typography>

                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                        fontSize: { md: "18px", sm: "18px", xs: "18px" },
                                    }}
                                >
                                    Easy To Set Up, Easy To Customise And Easy To Scale. Help your project management teams can assist more projects faster with AI based forecasting & project management tools
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-1 items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={easy}
                                    width="300"
                                    height="50"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={team}
                                    width="380"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full lg:w-1/2 mt-5">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    // textAlign="end"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 20, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                    }}
                                >
                                    Team Efficiency
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // textAlign="end"
                                    // fontSize="18px"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                        fontSize: { md: "18px", sm: "18px", xs: "18px" },
                                    }}
                                >
                                    An integrated StackArck ERP system automates project creation by automatically capturing data from the admin & user. This provides audits trails for better visibility and also helps increase efficiency and accuracy.
                                </Typography>
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    Live Chat’s
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // fontSize="18px"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                        fontSize: { md: "18px", sm: "18px", xs: "18px" },
                                    }}
                                >
                                    StackArch ERP, each project can collectively ensure communication between each member in the same projects, clients have the privileges to take meetings with the project team.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={chat}
                                    width="300"
                                    height="50"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={standard}
                                    width="300"
                                    height="50"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    // textAlign="end"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                    }}
                                >
                                    Standardisation
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // textAlign="end"
                                    // fontSize="18px"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                        fontSize: { md: "18px", sm: "18px", xs: "18px" },
                                    }}
                                >
                                    StackArch ERP, keep improvement of project management, operational performance, cost reduction through decreased process errors, facilitation of communication, profiting from expert knowledge form the Industry standards.
                                </Typography>
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    Collaborate
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // fontSize="18px"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                        fontSize: { md: "18px", sm: "18px", xs: "18px" },
                                    }}
                                >
                                    StackArch ERP easily integrates with the third party ERP which is related to the field of financial, CRM, Human capital, Inventory modules. Each stages of development our team create easy integration modules for existing ERP for architecture institutions.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={collab}
                                    width="300"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={manage}
                                    width="300"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full lg:w-1/2 mt-5">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    // textAlign="end"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                    }}
                                >
                                    Management
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // textAlign="end"
                                    // fontSize="18px"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                        fontSize: { md: "18px", sm: "18px", xs: "18px" },
                                    }}
                                >
                                    Managing each project with less effort and providing better managerial decision making in the terms right time. Each managerial insight can keep in particular problem statements, to recover the stages of the project management system with timely delivery.
                                </Typography>
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    On-cloud use
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // fontSize="18px"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                        fontSize: { md: "18px", sm: "18px", xs: "18px" },
                                    }}
                                >
                                    Now part of the technology shifting , we create the StackArch ERP with an intelligent cloud based solution, for easy use. Moving to cloud based solutions can keep your productivity increased with your team as well.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={cloud}
                                    width="300"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={plan}
                                    width="300"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full lg:w-1/2 mt-5">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    // textAlign="end"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                    }}
                                >
                                    Planning
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // textAlign="end"
                                    // fontSize="18px"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "left", sm: "end", xs: "center" },
                                        fontSize: { md: "18px", sm: "18px", xs: "18px" },
                                    }}
                                >
                                    We create each project management with proper planning, with the team scheduling, and managerial control over all that. In planning phase, plan is made and strategies are set, taking into consideration the company policies, procedures and rules Planning provides direction.
                                </Typography>
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{
                                        fontSize: { xs: 22, md: 32, sm: 32 },
                                        color: "#009ADF",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                    }}
                                >
                                    Lower cost
                                </Typography>

                                <Typography
                                    variant="body2"
                                    // fontSize="18px"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        textAlign: { md: "initial", sm: "initial", xs: "center" },
                                        fontSize: { md: "18px", sm: "18px", xs: "18px" },
                                    }}
                                >
                                    StackArch ERP can reduce the additional cost that acquired on a particular project during the life cycle of the project delivery. We concentrate on each project stage while consuming the least cost to timely delivery.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={low}
                                    width="300"
                                    height="100"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                </Stack>
            </Container>
        </>
    )
}

export default FeaturesCards