import { Container, Typography, Stack, Box, Grid } from "@mui/material";
import React from "react";
import BlogCategories from "./BlogCategories";
import BlogSlider from "./BlogSlider";
import RecentBlog from "./RecentBlog";

const BlogSliderSection = () => {
    return (
        <>
            <Container maxWidth="xl" sx={{ pt: 12 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <BlogSlider />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <RecentBlog />
                    </Grid>
                </Grid>
            </Container>

            <div
                style={{
                    backgroundImage:
                        "linear-gradient(180deg, #0B0D23 83.33%, #242242 100%)",
                }}
            >
                <BlogCategories />
            </div>
        </>
    );
};

export default BlogSliderSection;
