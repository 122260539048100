import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Card, Container, Grid, Pagination, Paper, Tabs } from "@mui/material";
import BlogPagination from "./BlogPagination";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BlogTabs from "./BlogTabs";
import FadeLoader from "react-spinners/FadeLoader";
import axios from "axios";


const styles = {
    pagination: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: '3rem',
    },
    paginationButton: {
        width: '40px',
        height: '40px',
        fontFamily: 'inherit',
        fontWeight: 600,
        fontSize: '16px',
        margin: '0 10px',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        color: 'white',
        border: '1px solid #1976d2',
    },
    activeButton: {
        fontWeight: 900,
        background: 'linear-gradient(180deg, #00BFFF 0%, #1E90FF 100%) !important',
        color: '#101010 !important',
    },
};

const BlogCategories = () => {
    const [value, setValue] = React.useState("1");
    const [categories, setCategories] = useState([]);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const [blogs, setBlogs] = useState([]);
    const [maxRecords, setMaxRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(6);
    const [active, setActive] = useState(categories[0]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        getBlogs();

    }, [currentPage]);

    const getBlogs = () => {
        setIsLoading(true)
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/blog/user/all?category=stackarch&size=${postsPerPage}&page=${currentPage - 1}`
            )
            .then((res) => {
                setBlogs(res.data.data.blogPosts);
                setIsLoading(false)
                setMaxRecords(Math.ceil(res.data.data.maxRecords / postsPerPage));
                console.log(res.data);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            });
    };
    const handleClick = (id) => {
        getBlogs(id);
    };

    const handleChange = (event, newValue, id) => {
        setValue(newValue);
        console.log(id);
        getBlogs(newValue);
    };

    return (
        <Container maxWidth="xl" sx={{ marginTop: "160px" }}>
            <Box sx={{ width: "100%", typography: "body1", }}>

                <Container>
                    {
                        isLoading ?
                            <Stack alignItems={"center"} p={{ xs: 5, md: 10 }}>
                                <FadeLoader color="#ddd" />
                            </Stack>
                            :
                            <Grid container rowSpacing={2} columnSpacing={5}>
                                {blogs?.map((blog, index) => (
                                    <Grid item md={4} sm={6} xs={12} >
                                        <BlogTabs data={blog} />
                                    </Grid>
                                ))}
                            </Grid>
                    }
                </Container>
                <div
                    className="pagination"
                    style={{ paddingBottom: '50px', marginTop: '3rem', ...styles.pagination }}
                >
                    <Pagination
                        count={maxRecords}
                        page={currentPage}
                        onChange={(e, newVal) => setCurrentPage(newVal)}
                        color="primary"
                        sx={{
                            '& .MuiPaginationItem-root': {
                                ...styles.paginationButton,
                            },
                            '& .Mui-selected': {
                                ...styles.activeButton,
                            },
                        }}
                    />
                </div>
                {/* <BlogPagination
                    totalPosts={maxRecords}
                    postsPerPage={postsPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                /> */}
            </Box>
        </Container>
    );
};

export default BlogCategories;
