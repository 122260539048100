import { Container, Stack, Typography } from '@mui/material'
import React from 'react'

import Frame from "../assets/Frame.webp"
import time from "../assets/time.webp"
import shapesVector from "../assets/shapesVector.webp"

function FeatureBanner2() {
    return (
        <div style={{ backgroundImage: `url(${shapesVector})` }}>
            <Container maxWidth="xl" >
                <Stack spacing={8} py={5}>

                    <Stack
                        sx={{
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex flex-1 items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight={{ xs: "600", md: "bold" }}
                                    sx={{
                                        color: "#ffff",
                                        fontSize: { xs: "28px", md: 32, sm: 32 },
                                        textAlign: { xs: "center", md: "initial", sm: "initial" },
                                        lineHeight: { xs: "35px", md: "39px" }
                                    }}
                                >
                                    The Ultimate Project Management ERP Software for Architects, architectural firms & Construction firms.
                                </Typography>

                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "28px",
                                    }}
                                >
                                    StackArch provides architectural firms with a comprehensive project management solution. It provides a robust set of features. Architects can easily create projects, define project timelines, and allocate resources effectively. The software enables seamless collaboration among team members and facilitates efficient project execution, ensuring that deadlines are met and milestones are achieved.



                                </Typography>
                                <Typography
                                    variant="body2"

                                    marginTop="20px"
                                    sx={{
                                        color: "#ffff",
                                        "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "28px",
                                    }}
                                >
                                    StackArch has an easily controllable dashboard to handle the project management very accurately. From the dashboard itself admin can manage the team productivity analysis, analytical project management and forecasting the project timeline.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-1 items-center justify-center w-full lg:w-1/2 mb-5">
                            <div className="">
                                <img
                                    src={Frame}
                                    width="616"
                                    height="617"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        sx={{
                            flexDirection: { xs: "column-reverse", md: "row" },
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="flex flex-1 items-center justify-center w-full lg:w-1/2">
                            <div className="">
                                <img
                                    src={time}
                                    width="616"
                                    height="617"
                                    alt="Hero Illustration"
                                    layout="intrinsic"
                                    loading="eager"
                                    placeholder="blur"
                                />
                            </div>
                        </div>
                        <div className="flex flex-1 items-center w-full lg:w-1/2">
                            <div className="max-w-2xl mb-8">
                                <Typography
                                    variant="h6"
                                    fontWeight={{ xs: "600", md: "bold" }}
                                    sx={{ fontSize: { xs: "28px", md: 32, sm: 32 }, color: "#ffff", textAlign: { xs: "center", md: "left" } }}
                                >
                                    Enhance Your Project Management with StackArch
                                </Typography>

                                <Typography
                                    variant="body2"

                                    sx={{
                                        color: "#ffff",
                                        marginTop: "20px",
                                        "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "28px",
                                    }}
                                >
                                    StackArch analytical reporting gives a clarity in project detailing and stages are completed. StackArch Architectural ERP integrates all architectural based functions into a single integrated software program that runs on a single database so that various departments and areas can easily share information and communicate with each other. The integrated approach of StackArch Architectural ERP has tremendous power and potential in improving the efficiency, productivity, and competitiveness of the organisation.
                                </Typography>
                            </div>
                        </div>
                    </Stack>
                </Stack>
            </Container>
        </div>
    )
}

export default FeatureBanner2