import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        h1: {
            fontFamily: ["Alegreya", "serif"].join(","),
        },
        fontFamily: ["Poppins", "sans-serif"].join(","),
    },

    status: {
        danger: "#e53e3e",
    },
    palette: {
        primary: {
            main: "#ffff",
        },
        secondary: {
            main: "#282559",
            mainGradient: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) ",
        },
        neutral: {
            main: "#64748B",
            contrastText: "#fff",
        },
        text: {
            primary: "#ffff",
            secondary: "#0000",
        },
    },
    // breakpoints: {
    //   values: {
    //     xs: 0,
    //     sm: 576,
    //     md: 768,
    //     lg: 992,
    //     xl: 1200,
    //   },
    // },
});

export default theme;
