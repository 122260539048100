import styled from "@emotion/styled";
import { Box, Button, Container, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

import imgBan from "../assets/imgBan.webp"
import { useFormik } from "formik";
import * as Yup from "yup";

const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#ffff",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#ffff",
        borderWidth: "0.5px",
        borderRadius: "5px",
        boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
    "& .MuiInputBase-input": {
        color: "#ffff",
        borderWidth: "0.5px",
        borderRadius: "5px",
        boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#ffff",
            borderWidth: "0.5px",
            borderRadius: "5px",
            boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
        },
        "&:hover fieldset": {
            borderColor: "#ffff",
            borderWidth: "0.5px",
            borderRadius: "5px",
            boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#ffff",
            borderWidth: "0.5px",
            borderRadius: "5px",
            boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
        },

    },
});

function ContactForm({ title, image }) {

    const [category, setCategory] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const [Message, setMessage] = useState("");

    const [numberOfUsers, setNumberOfUsers] = useState("");


    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/category/contactus/user`)
            .then((res) => {
                setCategory(res.data.data.categories);
            })
            .catch((err) => console.log(err));
    };

    const ContactSchema = Yup.object().shape({
        fullname: Yup.string().required("name is required"),
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("Email is required"),
        phone: Yup.string()
            .required("Phone is required")
            .min(10, "Enter correct phone number"),
        budget: Yup.string()
            .required("Budget is required"),
        location: Yup.string()
            .required("Location is required"),
        category: Yup.string()
            .required("This field is required"),
        // numberOfUsers: Yup.string()
        //     .required("This field is required"),
        description: Yup.string()
            .required("This field is required"),
    });


    const formik = useFormik({
        initialValues: {
            fullname: "",
            email: "",
            phone: "",
            location: "",
            category: "",
            budget: "none",
            // numberOfUsers: "",
            description: ""
        },
        validationSchema: ContactSchema,
        onSubmit: async () => {
            await axios
                .post(`${process.env.REACT_APP_API_URL}/mail/contact`, { ...values, description: modifiedDescription + " - StackArch" })
                .then(async (response) => {
                    console.log(response)
                    if (!response.data.isError) {
                        setIsSuccess(true);
                        setMessage(response.data.message);
                        // e.target.reset();
                        // reset();
                    } else {
                        setIsSuccess(false);
                        setMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    setIsSuccess(false);
                    setMessage("Client Error. Please check the console.log for more info");
                    console.log(error);
                });
        },
    });

    const {
        errors,
        touched,
        values,
        handleSubmit,
        getFieldProps,
    } = formik;

    const description = values.description;
    const firstFullStopIndex = description.indexOf('.');

    const modifiedDescription = firstFullStopIndex !== -1
        ? description.slice(0, firstFullStopIndex + 1) + "\nNumber of Users - " + numberOfUsers + ".\n" + description.slice(firstFullStopIndex + 1)
        : "\nNumber of Users - " + numberOfUsers + ".\n" + description;

    return (
        <Stack
            // className="drop"
            sx={{
                pt: "50px",
                background: {
                    xs: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${image ? image : imgBan
                        })`,
                    md: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${imgBan})`,
                },
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: { xs: "cover", md: "cover" },
            }}
        >
            <Container maxWidth="xl">

                <Box sx={{ padding: { xs: 2, md: 5 } }}>

                    <Typography
                        variant="h4"
                        textAlign="center"
                        sx={{
                            fontFamily: "'Sansation', sans-serif",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "34px",
                            lineHeight: "123.91%",
                            textAlign: "center",
                            letterSpacing: "0.1em",
                            color: "white"
                        }}
                    >
                        {title ? title : "Drop us a line"}
                    </Typography>
                    <Typography
                        variant="h6"
                        textAlign="center"
                        fontSize={{ xs: "16px", md: "20px" }}
                        sx={{
                            fontWeight: { xs: 400, md: 500 },
                            mt: "10px",
                            color: "white"
                        }}
                    >
                        Tell us a bit more about your project
                    </Typography>
                    <Typography
                        variant="h6"
                        textAlign="center"
                        fontSize={{ xs: "16px", md: "20px" }}
                        sx={{
                            fontWeight: { xs: 400, md: 500 },
                            color: "white"
                        }}
                    >
                        Post your requirements here and we will contact you Shortly
                    </Typography>{" "}

                    {!isSuccess && (<Box sx={{ marginTop: "50px" }}>
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container spacing={4}>
                                <Grid item md={6} sm={6} xs={12}>

                                    <CssTextField
                                        focused
                                        placeholder="Enter your fullname"
                                        label="Fullname"
                                        fullWidth
                                        {...getFieldProps("fullname")}
                                        error={Boolean(touched.fullname && errors.fullname)}
                                        helperText={touched.fullname && errors.fullname}
                                    />
                                    {errors.fullname && (
                                        <div className="mt-1 text-sm text-red-400 invalid-feedback">
                                            {errors.fullname.message}
                                        </div>
                                    )}

                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>

                                    <CssTextField
                                        focused
                                        label="Email"
                                        placeholder="Enter your email"
                                        fullWidth
                                        {...getFieldProps("email")}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />


                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>

                                    <CssTextField
                                        focused
                                        label="Phone"
                                        placeholder="Enter phone number"
                                        fullWidth
                                        {...getFieldProps("phone")}
                                        error={Boolean(touched.phone && errors.phone)}
                                        helperText={touched.phone && errors.phone}
                                    />

                                    e
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>

                                    <CssTextField
                                        focused
                                        label="Location"
                                        placeholder="Enter Location"
                                        fullWidth
                                        {...getFieldProps("location")}
                                        error={Boolean(touched.location && errors.location)}
                                        helperText={touched.location && errors.location}
                                    />


                                </Grid>

                                <Grid item md={6} sm={6} xs={12}>


                                    <CssTextField select fullWidth focused
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Looking for"
                                        {...getFieldProps("category")}
                                        error={Boolean(touched.category && errors.category)}
                                        helperText={touched.category && errors.category}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiPaper-root": {
                                                    backgroundColor: "whitesmoke",
                                                    color: "#131331",
                                                },
                                            },
                                        }}
                                        sx={{
                                            ".MuiSvgIcon-root ": {
                                                fill: "white !important",
                                            },
                                        }}
                                    >
                                        {category?.filter((item) => item.name === "ERP SOLUTIONS")?.map((cat, i) => (
                                            <MenuItem value={cat._id}>{cat.name}</MenuItem>
                                        ))}
                                    </CssTextField>


                                </Grid>
                                {/* <Grid item md={6} sm={6} xs={12}>
                                    <CssTextField select fullWidth focused
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Budget"
                                        {...getFieldProps("budget")}
                                        error={Boolean(touched.budget && errors.budget)}
                                        helperText={touched.budget && errors.budget}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiPaper-root": {
                                                    backgroundColor: "whitesmoke",
                                                    color: "#131331",
                                                },
                                            },
                                        }}
                                        sx={{
                                            ".MuiSvgIcon-root ": {
                                                fill: "white !important",
                                            },
                                        }}
                                    >
                                        <MenuItem value={"Less than $750"}>
                                            Less than $750
                                        </MenuItem>
                                        <MenuItem value={"$750-$1000"}>$750-$1000</MenuItem>
                                        <MenuItem value={"$1000-$2500"}>$1000-$2500</MenuItem>
                                        <MenuItem value={"$2500-$5000"}>$2500-$5000</MenuItem>
                                        <MenuItem value={"$5000+"}>$5000+</MenuItem>
                                    </CssTextField>
                                </Grid> */}


                                <Grid item md={6} sm={12} xs={12}>

                                    <CssTextField
                                        focused
                                        label="Number of Users"
                                        placeholder="Enter Number of Users "
                                        fullWidth
                                        value={numberOfUsers}
                                        onChange={(e) => setNumberOfUsers(e.target.value)}
                                    // {...getFieldProps("numberOfUsers")}
                                    // error={Boolean(touched.numberOfUsers && errors.numberOfUsers)}
                                    // helperText={touched.numberOfUsers && errors.numberOfUsers}
                                    />


                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>

                                    <CssTextField
                                        focused
                                        label="Describe your project"
                                        placeholder="Describe "
                                        fullWidth
                                        {...getFieldProps("description")}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                    />


                                </Grid>
                            </Grid>

                            <Box sx={{ marginTop: "50px" }}>
                                <Button
                                    type="submit"
                                    sx={{
                                        display: "flex",
                                        margin: "0 auto",
                                        padding: "10px 20px",
                                        color: "white",
                                        background: "linear-gradient(180deg, #009ADE 0%, #0057A9 100%) !important",
                                    }
                                    }
                                >
                                    Submit
                                </Button>
                            </Box>

                        </form>
                    </Box>)}


                    {isSuccess && (

                        <div className="flex flex-col items-center justify-center h-full text-center text-white rounded-md mt-4" >
                            <svg
                                width="60"
                                height="60"
                                className="text-green-300"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                                    stroke="currentColor"
                                    strokeWidth="3"
                                />
                            </svg>
                            <h3 className="py-5 text-xl text-green-500">
                                Message sent successfully
                            </h3>
                            <p className="text-gray-700 md:px-3">{Message}</p>
                            <button
                                className="mt-6 text-indigo-600 focus:outline-none"
                                onClick={() => setIsSuccess(false)}
                            >
                                Go back
                            </button>
                        </div>
                    )}

                </Box>
            </Container>
        </Stack>
    );
}

export default ContactForm;
