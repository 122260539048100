import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import BlogCard from "./BlogCard";
import axios from "axios";
import "./blogCard.css"

const BlogSlider = () => {
    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/blog/user/latest/description?size=4&category=stackarch`
            )
            .then((res) => {
                setBlogs(res.data.data.blogPosts);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return (
        <Slider {...settings}>
            {blogs.map((e) => (
                <div style={{ position: "relative", height: "850px" }}>
                    <BlogCard data={e} />
                </div>
            ))}
        </Slider>
    );
};
export default BlogSlider;
