import React from "react";
import { Box, Typography } from "@mui/material";
import PopUp from "./PopUp"
import construction from "../assets/construction.webp"

function FeatureBanner() {
    return (
        <>
            <Box
                className="banner"
                sx={{
                    background: `url(${construction})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100%",
                    paddingBottom: { xs: "150px", md: "50px" },
                    // height: "470px",
                    height: { xs: "auto", md: "110vh" },
                    display: "flex",
                    flexWrap: "wrap",
                    // margin: { xs: "10px", md: "30px" },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: { xs: "20px", md: "50px" },
                        marginTop: { xs: "100px", lg: "100px" },
                        marginLeft: { xs: "0px", md: "70px" },
                    }}
                >
                    <Typography
                        variant="h3"
                        fontWeight="bold"
                        textAlign="initial"
                        sx={{
                            fontSize: {
                                lg: "50px",
                                md: 50,
                                sm: 32,
                                xs: "28px",
                            },
                            color: "white",
                            // marginTop: { md: "50px", xs: "0px" },
                            width: { xs: "100%", md: "80%", lg: "60% !important" }
                        }}
                    >
                        Fast & Visual All-in-One Project Management ERP Software for Architects
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "white",
                            fontSize: { md: "22px", xs: "18px", sm: "22px" },
                            marginTop: { md: "25px", xs: "10px" },
                            width: { xs: "100%", md: "80%", lg: "50% !important" }
                        }}
                    >
                        StackArch is the game-changing project management solution designed specifically for architects. StackArch empowers architectural teams to manage projects, workflows, and task tracking with unparalleled efficiency and clarity. With its innovative approach, StackArch offers a comprehensive suite of features tailored to the unique needs of architects, ensuring seamless collaboration, streamlined resource allocation, and detailed project tracking.

                    </Typography>
                    <PopUp margin={"50px"} background={"#e6f7ff"} color={"#4F46E5"} />
                </Box>
            </Box>

        </>
    );
}

export default FeatureBanner;
