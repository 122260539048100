import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import HomeBanner from '../components/HomeBanner'
import shapesVector from "../assets/shapesVector.webp"
import shapeBg from "../assets/shapeBg.webp"
import workFlow from "../assets/workFlow.webp"
import blurredBg from "../assets/blurredBg.webp"

import startupIndia from "../assets/startupIndia.webp"
import startupMission from "../assets/startupMission.webp"

import HowITWorks from '../components/HowITWorks'
import StageCards from '../components/StageCards'
import Features from '../components/Features'
import { Helmet } from 'react-helmet'
import PopUp from '../components/PopUp'
import ContactForm from '../components/ContactForm'


import monitor from "../assets/icons/monitors.png"
import doc from "../assets/icons/doc.png"
import collab from "../assets/icons/collab.png"
import project from "../assets/icons/project.png"
import tools from "../assets/icons/tools.png"

import list from "../assets/vectors/list.png"
import card from "../assets/vectors/card.png"
import collabs from "../assets/vectors/collab.png"
import deadline from "../assets/vectors/deadline.png"
import task from "../assets/vectors/task.png"
import gain from "../assets/vectors/gain.png"


function HomePage() {

    const cardData = [
        {
            title: "Live Chat Feature",
            desc: "Effortless tracking of important project documents and revisions.",
            img: monitor,
        },
        {
            title: "Robust Document Management",
            desc: "Efficient management of resources for optimized project execution",
            img: doc,
        },
        {
            title: "Resource Allocation Tools",
            desc: "Efficient management of resources for optimized project execution",
            img: tools,
        },
        {
            title: "Comprehensive Project Tracking and Reporting",
            desc: "Detailed insights into project progress and performance",
            img: project,
        },
        {
            title: "Collaborative Platform",
            desc: "Seamless coordination and communication with multiple stakeholders",
            img: collab,
        },
    ];

    const homeContents = [
        {
            title: "Lists",
            content: "Tailor lists to encapsulate the diverse project stages of your architectural endeavors. StackArch allows the customization of workflows to match the particular needs of architectural projects, giving you a holistic view of the project lifecycle.",
            image: list,
        },

        {
            title: "Cards",
            content: "In StackArch, cards represent individual tasks and hold all the essential information needed for successful project execution. Architects can assign team members, set due dates, leave comments, and track progress as tasks move across lists.",
            image: card,
        },
        {
            title: "Seamlessness in Collaboration            ",
            content: "Seamless team collaboration is integrated within StackArch. This feature fosters streamlined communication, alignment, and shared responsibility throughout the project lifecycle, ensuring that the involved teams are in sync. ",
            image: collabs,
        },
        {
            title: "Deadline Management",
            content: "Assign due dates, set reminders, and monitor project timelines with ease. Task deadlines can be managed with precision, ensuring that project progress remains aligned with established timelines.",
            image: deadline,
        },
        {
            title: "Task Breakdown and Organization",
            content: "Simplify complex architectural tasks into manageable subtasks with checklists. Check items off the list as tasks are completed, bringing clarity and organization to intricate project elements.",
            image: task,
        },
        {
            title: "Visual Insights and Detailed Understanding",
            content: "Gain a comprehensive and expanded view of task details, progress, and updates within an intuitive interface in StackArch. The expanded view provides a quick and granular understanding of task status.  ",
            image: gain,
        },
    ];


    return (
        <>
            <Helmet>
                <title>StackArch | Home</title>
            </Helmet>
            <Stack
                pt={{ xs: 10, md: 12 }} sx={{
                    background: "rgba(19, 19, 49)",
                    opacity: "1",
                    width: "100%"
                }}
            >
                <HomeBanner />

                {/*  */}
                <div style={{ backgroundImage: `url(${shapesVector})` }}>
                    <Container maxWidth="xl" >
                        <Stack spacing={8} py={5}>

                            {
                                homeContents.map((content, index) => (


                                    <Stack
                                        sx={{
                                            flexDirection: { xs: "column", md: index % 2 === 0 ? "row" : "row-reverse" },
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div className="flex flex-1 items-center w-full lg:w-1/2">
                                            <div className="max-w-2xl mb-8">
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={{ xs: "600", md: "bold" }}
                                                    sx={{
                                                        color: "#ffff",
                                                        fontSize: { xs: "28px", md: 32, sm: 32 },
                                                        textAlign: { xs: "center", md: "initial", sm: "initial" },
                                                        lineHeight: { xs: "35px", md: "39px" }
                                                    }}
                                                >
                                                    {content?.title}
                                                </Typography>

                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: "#ffff",
                                                        marginTop: "20px",
                                                        "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "28px",
                                                    }}
                                                >
                                                    {content.content}



                                                </Typography>

                                            </div>
                                        </div>
                                        <div className="flex flex-1 items-center justify-center w-full lg:w-1/2 mb-5">
                                            <div className="">
                                                <img
                                                    src={content.image}
                                                    width="616"
                                                    height="617"
                                                    alt="Hero Illustration"
                                                    layout="intrinsic"
                                                    loading="eager"
                                                    placeholder="blur"
                                                />
                                            </div>
                                        </div>
                                    </Stack>

                                ))
                            }
                            {/* <Stack
                                sx={{
                                    flexDirection: { xs: "column-reverse", md: "row" },
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className="flex flex-1 items-center justify-center w-full lg:w-1/2">
                                    <div className="">
                                        <img
                                            src=""
                                            width="616"
                                            height="617"
                                            alt="Hero Illustration"
                                            layout="intrinsic"
                                            loading="eager"
                                            placeholder="blur"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-1 items-center w-full lg:w-1/2">
                                    <div className="max-w-2xl mb-8">
                                        <Typography
                                            variant="h6"
                                            fontWeight={{ xs: "600", md: "bold" }}
                                            sx={{ fontSize: { xs: "28px", md: 32, sm: 32 }, color: "#ffff", textAlign: { xs: "center", md: "left" } }}
                                        >
                                            Cards
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            sx={{
                                                color: "#ffff",
                                                marginTop: "20px",
                                                "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "28px",
                                            }}
                                        >
                                            In StackArch, cards represent individual tasks and hold all the essential information needed for successful project execution. Architects can assign team members, set due dates, leave comments, and track progress as tasks move across lists.

                                        </Typography>
                                    </div>
                                </div>
                            </Stack>

                            <Stack
                                sx={{
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className="flex flex-1 items-center w-full lg:w-1/2">
                                    <div className="max-w-2xl mb-8">
                                        <Typography
                                            variant="h6"
                                            fontWeight={{ xs: "600", md: "bold" }}
                                            sx={{
                                                color: "#ffff",
                                                fontSize: { xs: "28px", md: 32, sm: 32 },
                                                textAlign: { xs: "center", md: "initial", sm: "initial" },
                                                lineHeight: { xs: "35px", md: "39px" }
                                            }}
                                        >
                                            Seamlessness in Collaboration
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: "#ffff",
                                                marginTop: "20px",
                                                "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "28px",
                                            }}
                                        >
                                            Seamless team collaboration is integrated within StackArch. This feature fosters streamlined communication, alignment, and shared responsibility throughout the project lifecycle, ensuring that the involved teams are in sync.
                                        </Typography>

                                    </div>
                                </div>
                                <div className="flex flex-1 items-center justify-center w-full lg:w-1/2 mb-5">
                                    <div className="">
                                        <img
                                            src={""}
                                            width="616"
                                            height="617"
                                            alt="Hero Illustration"
                                            layout="intrinsic"
                                            loading="eager"
                                            placeholder="blur"
                                        />
                                    </div>
                                </div>
                            </Stack>

                            <Stack
                                sx={{
                                    flexDirection: { xs: "column-reverse", md: "row" },
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className="flex flex-1 items-center justify-center w-full lg:w-1/2">
                                    <div className="">
                                        <img
                                            src=""
                                            width="616"
                                            height="617"
                                            alt="Hero Illustration"
                                            layout="intrinsic"
                                            loading="eager"
                                            placeholder="blur"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-1 items-center w-full lg:w-1/2">
                                    <div className="max-w-2xl mb-8">
                                        <Typography
                                            variant="h6"
                                            fontWeight={{ xs: "600", md: "bold" }}
                                            sx={{ fontSize: { xs: "28px", md: 32, sm: 32 }, color: "#ffff", textAlign: { xs: "center", md: "left" } }}
                                        >
                                            Deadline Management
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            sx={{
                                                color: "#ffff",
                                                marginTop: "20px",
                                                "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "28px",
                                            }}
                                        >
                                            Assign due dates, set reminders, and monitor project timelines with ease. Task deadlines can be managed with precision, ensuring that project progress remains aligned with established timelines.
                                        </Typography>
                                    </div>
                                </div>
                            </Stack>

                            <Stack
                                sx={{
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className="flex flex-1 items-center w-full lg:w-1/2">
                                    <div className="max-w-2xl mb-8">
                                        <Typography
                                            variant="h6"
                                            fontWeight={{ xs: "600", md: "bold" }}
                                            sx={{
                                                color: "#ffff",
                                                fontSize: { xs: "28px", md: 32, sm: 32 },
                                                textAlign: { xs: "center", md: "initial", sm: "initial" },
                                                lineHeight: { xs: "35px", md: "39px" }
                                            }}
                                        >
                                            Task Breakdown and Organization
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: "#ffff",
                                                marginTop: "20px",
                                                "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "28px",
                                            }}
                                        >
                                            Simplify complex architectural tasks into manageable subtasks with checklists. Check items off the list as tasks are completed, bringing clarity and organization to intricate project elements.
                                        </Typography>

                                    </div>
                                </div>
                                <div className="flex flex-1 items-center justify-center w-full lg:w-1/2 mb-5">
                                    <div className="">
                                        <img
                                            src={""}
                                            width="616"
                                            height="617"
                                            alt="Hero Illustration"
                                            layout="intrinsic"
                                            loading="eager"
                                            placeholder="blur"
                                        />
                                    </div>
                                </div>
                            </Stack>


                            <Stack
                                sx={{
                                    flexDirection: { xs: "column-reverse", md: "row" },
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className="flex flex-1 items-center justify-center w-full lg:w-1/2">
                                    <div className="">
                                        <img
                                            src=""
                                            width="616"
                                            height="617"
                                            alt="Hero Illustration"
                                            layout="intrinsic"
                                            loading="eager"
                                            placeholder="blur"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-1 items-center w-full lg:w-1/2">
                                    <div className="max-w-2xl mb-8">
                                        <Typography
                                            variant="h6"
                                            fontWeight={{ xs: "600", md: "bold" }}
                                            sx={{ fontSize: { xs: "28px", md: 32, sm: 32 }, color: "#ffff", textAlign: { xs: "center", md: "left" } }}
                                        >
                                            Visual Insights and Detailed Understanding
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            sx={{
                                                color: "#ffff",
                                                marginTop: "20px",
                                                "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "28px",
                                            }}
                                        >
                                            Gain a comprehensive and expanded view of task details, progress, and updates within an intuitive interface in StackArch. The expanded view provides a quick and granular understanding of task status.

                                        </Typography>
                                    </div>
                                </div>
                            </Stack> */}

                        </Stack>
                    </Container>
                </div>

                {/*  */}

                <Stack p={{ xs: 2, md: 6 }}
                    sx={{
                        background: `url(${shapesVector})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <HowITWorks />



                    <Stack alignItems={"center"} mb={5} mt={8}>
                        <Typography sx={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "28px", md: "48px" }, "lineHeight": { xs: "39px", md: "56px" }, "color": "#FFFFFF", textAlign: { xs: "center", md: "left" } }}
                        >
                            Features Of STACKARCH.</Typography>
                        <Grid container sx={{ width: "90%", m: "0 auto", mt: { xs: "50px", md: "80px" }, }} alignItems="center" justifyContent="center" rowGap={8} columnGap={7}>
                            {
                                cardData.map((card) => (
                                    <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
                                        <Stack sx={{
                                            width: "100%",
                                            height: "285px",
                                            background: "#FFF",
                                            borderRadius: "22px",
                                            boxShadow: "0px 1px 7px 18px rgba(1, 2, 178, 0.25)",
                                            p: 3

                                        }} alignItems={"center"} justifyContent={"center"} gap={2}>
                                            <Box component={"img"} src={card.img} width={"75px"} />
                                            <Typography sx={{ "color": "#009ADE", "fontSize": "17px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "normal", textAlign: "center" }}>{card.title}</Typography>
                                            <Typography sx={{ "fontSize": "15px", "fontStyle": "normal", "fontWeight": "400", color: "black", "lineHeight": "22.5px", textAlign: "center" }}>{card.desc}</Typography>
                                        </Stack>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Stack>
                </Stack>





                <Stack
                    gap={5}
                    alignItems={"center"} justifyContent={"center"} p={6} height={"385px"}
                    sx={{
                        background: `url(${shapeBg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <Typography maxWidth={"1026px"} sx={{ "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "24px", md: "36px" }, "lineHeight": { xs: "35px", md: "42px" }, "textAlign": "center", "color": "#0099DD" }}>A complete architectural project management ERP software available in web & mobile platform for Architects</Typography>
                    <PopUp />
                </Stack>



                <Stack p={{ xs: 2, md: 6 }} mt={{ xs: 4 }}
                    alignItems="center"
                    justifyContent={"center"}
                    sx={{
                        background: `url(${shapesVector})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <Box sx={{ maxWidth: "561px" }}>
                        <Typography textAlign={"center"} sx={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "28px", md: "48px" }, "lineHeight": { xs: "39px", md: "56px" }, "color": "#FFFFFF" }}>Unique Workflow</Typography>
                        <Typography mt={3} sx={{ "fontStyle": "normal", "fontWeight": "400", "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "23px", "textAlign": "center", "color": "#FFFFFF" }}>The integrated approach of StackArch has tremendous power and potential in improving the efficiency, productivity, and competitiveness of the organisation.</Typography>
                    </Box>
                    <Box mt={10} component={"img"} alt='workflow' src={workFlow} width={{ xs: "100%", md: "60%" }} />
                </Stack>

                <Stack p={{ xs: 3, md: 6 }}
                    alignItems="center"
                    justifyContent={"center"}
                    sx={{
                        background: `url(${blurredBg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <StageCards />
                </Stack>

                {/* <Stack p={{ xs: 2, md: 10 }}
                    alignItems="center"
                    justifyContent={"center"}
                    sx={{
                        background: `url(${shapesVector})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <Features />
                </Stack> */}






                {/* <Stack p={{ xs: 2, md: 6 }} direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 4, md: 0 }}
                    alignItems="center"
                    justifyContent={"space-around"}
                    sx={{
                        "background": "linear-gradient(180deg, #FFFFFF 0%, #EBF9FF 26.04%, #B0E7FF 100%)",
                        backgroundSize: "cover",
                    }}
                >
                    <Typography sx={{ "fontWeight": "600", "fontSize": { xs: "28px", md: "40px" }, "lineHeight": "177.52%", "letterSpacing": "0.005em", "color": "#000000" }}>Recognisation:</Typography>
                    <Box component={"img"} alt='startup mission' src={startupMission} />
                    <Box component={"img"} alt='startun india' src={startupIndia} />
                </Stack> */}
                <ContactForm />

            </Stack>
        </>
    )
}

export default HomePage