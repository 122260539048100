import { Stack } from '@mui/material'
import React from 'react'
import FeatureBanner from '../components/FeatureBanner'
import FeatureBanner2 from '../components/FeatureBanner2'
import FeaturesCards from '../components/FeaturesCards'
import homeShape from "../assets/homeShapes.webp"
import FeatureDescription from '../components/FeatureDescription'
import ContactForm from '../components/ContactForm'
import { Helmet } from 'react-helmet'
import FeatureDeliver from '../components/FeatureDeliver'
function FeaturesPage() {
    return (
        <>
            <Helmet>
                <title>StackArch | Features</title>
            </Helmet>
            <Stack pt={12} sx={{
                background: "rgba(19, 19, 49)",
                opacity: "1",
                width: "100%"
            }}>
                <FeatureBanner />
                <FeatureBanner2 />
                <FeatureDeliver />
                <Stack my={5} sx={{
                    background: `url(${homeShape})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}>
                    <FeaturesCards />
                </Stack>
                <FeatureDescription />
                <ContactForm />
            </Stack>
        </>
    )
}

export default FeaturesPage