import { Box, Stack, Typography, Grid } from '@mui/material'
import checked from "../assets/checked 1.webp"
import draw from "../assets/image 13.webp"
import compound from "../assets/image 14.webp"
import panton from "../assets/pantone 1.webp"
import landscape from "../assets/image 11.webp"
import plumbing from "../assets/plumbing 1.webp"
import scale from "../assets/project 1.webp"
import pool from "../assets/swimming 1.webp"


import React from 'react'

function StageCards() {
    return (
        <>
            <Stack alignItems={"center"} justifyContent="center" p={5} width="100%">
                <Box maxWidth={"561px"} >
                    <Typography
                        sx={{ "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "28px", md: "48px" }, "lineHeight": { xs: "39px", md: "56px" }, "textAlign": "center", "color": "#FFFFFF" }}
                    >Project Integration Stages</Typography>
                    <Typography mt={2} sx={{ "fontStyle": "normal", "fontWeight": "400", "fontSize": { xs: "18px", md: "20px" }, "lineHeight": "23px", "textAlign": "center", "color": "#FFFFFF" }}>You can increase your business productivity and growth when you integrate SaasHub with various Platforms.</Typography>
                </Box>

                <Grid container mt={5} spacing={4} alignItems="center" justifyContent={"center"}>

                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Box sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} src={checked} alt='checked' />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Approvals</Typography>
                        </Box>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Box mt={3} sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} alt='draw' src={draw} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Architectural Draw</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Box sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} alt='compound' src={compound} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Compound Wall</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Box mt={3} sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} alt='panton' src={panton} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Interior</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Box sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} alt='landscape' src={landscape} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Landscape</Typography>
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Box mt={3} sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} alt='plumbing' src={plumbing} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>MEP - ELV</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>


                        <Box sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} alt='scale' src={scale} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Presentation Draw</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Box mt={3} sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} alt='pool' src={pool} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Swimming Pool</Typography>
                        </Box>
                    </Grid>


                </Grid>

                {/* <Stack mt={5} spacing={4}>

                    <Stack direction={{ xs: "column", md: "row", }} sx={{ gap: "30px", }}>

                        <Box sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} src={checked} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Approvals</Typography>
                        </Box>

                        <Box mt={3} sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} src={draw} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Architectural Draw</Typography>
                        </Box>

                        <Box sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} src={compound} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Compound Wall</Typography>
                        </Box>

                        <Box mt={3} sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} src={panton} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Interior</Typography>
                        </Box>

                    </Stack>

                    <Stack direction={{ xs: "column", md: "row" }} sx={{ gap: "30px" }}>

                        <Box sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} src={landscape} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Landscape</Typography>
                        </Box>

                        <Box mt={3} sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} src={plumbing} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>MEP - ELV</Typography>
                        </Box>

                        <Box sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} src={scale} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Presentation Draw</Typography>
                        </Box>

                        <Box mt={3} sx={
                            { "width": "293px", "height": "308px", "background": "#FFFFFF", "borderRadius": "10px", display: "flex", alignItems: "center", justifyContent: "center", p: 2, flexDirection: "column", gap: "20px" }
                        }>
                            <Box component={"img"} src={pool} />
                            <Typography sx={{ "fontWeight": "500", "fontSize": { xs: "28px", md: "32px" }, "lineHeight": "38px", "color": "#000000", textAlign: "center" }}>Swimming Pool</Typography>
                        </Box>
                    </Stack>

                </Stack> */}

            </Stack>
        </>
    )
}

export default StageCards