import { AccessTime, CalendarMonth } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Pagination,
    Stack,
    Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import { useStyles } from "../../styles";
import ContactForm from "../ContactForm";
import "./blogCard.css";
import PopUp from "../PopUp";


const styles = {
    pagination: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: '3rem',
    },
    paginationButton: {
        width: '40px',
        height: '40px',
        fontFamily: 'inherit',
        fontWeight: 600,
        fontSize: '16px',
        margin: '0 10px',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        color: 'white',
        border: '1px solid #1976d2',
    },
    activeButton: {
        fontWeight: 900,
        background: 'linear-gradient(180deg, #00BFFF 0%, #1E90FF 100%) !important',
        color: '#101010 !important',
    },
};


const BlogDetail = () => {
    // const { id } = useParams();
    const [detail, setDetail] = useState();
    const [similar, setSimilar] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(6);
    const [searchParams, setSearchParams] = useSearchParams();
    const [maxRecords, setMaxRecords] = useState(0);

    const id = searchParams.get("id");
    const navigate = useNavigate();
    const classes = useStyles();
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const blogDetail = await axios.get(
                    `${process.env.REACT_APP_API_URL}/blog/user?blogId=${id}`
                );
                setDetail(blogDetail.data.data.blogPost);
            } catch (e) {
                console.log(e);
            }
        };

        fetchData();
    }, [searchParams]);

    const fetchSimilarBlogs = async () => {
        try {
            const similarBlogs = await axios.get(
                `${process.env.REACT_APP_API_URL}/blog/user/other/category?categoryId=${detail?.category}&blogId=${id}&page=${currentPage - 1}&size=${postsPerPage}`
            );
            setSimilar(similarBlogs.data.data.blogPosts);
            setMaxRecords(Math.ceil(similarBlogs.data.data.maxRecords / postsPerPage))
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        fetchSimilarBlogs();
    }, [detail, currentPage, postsPerPage, searchParams])

    return (
        <div>
            <Helmet>
                <title>Stackroots | {`${detail?.title}`}</title>
                <meta name="title" content={detail?.title} />
            </Helmet>

            <Container maxWidth="lg">
                <Box pt={20} mb={10}>
                    <Slider {...settings}>
                        {detail?.images.map((image) => {
                            return (
                                <img
                                    src={image}
                                    className="image"
                                    alt="/"
                                    width="100%"
                                    style={{ marginTop: "50px" }}
                                />
                            );
                        })}
                    </Slider>
                    <Typography
                        className={classes.mainHeader}
                        sx={{
                            textAlign: { xs: "center", md: "center", sm: "center" },
                            mt: 3,
                            mb: 3,
                            "fontFamily": "Poppins,", "fontSize": "30px", "letterSpacing": "0", "fontStyle": "normal", "fontWeight": "700", "color": "rgb(255,255,255)"
                        }}
                    >
                        {detail?.title}
                    </Typography>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        gap={2}
                        justifyContent="center"
                        alignContent={"center"}
                        alignItems="center"
                    >
                        <Stack direction={"row"} alignItems="center" gap={2}>
                            <Typography
                                variant="subtitle1"
                                display={"flex"}
                                alignItems={"center"}
                            >
                                <CalendarMonthIcon
                                    sx={{
                                        marginRight: "5px",
                                        fontSize: "30px",
                                        color: "#1270c8",
                                    }}
                                />
                                {moment(detail?.createdAt).format("ll")}
                            </Typography>

                            <Typography
                                variant="subtitle1"
                                display={"flex"}
                                alignItems={"center"}
                            >
                                <AccessTimeIcon
                                    sx={{
                                        marginRight: "5px",
                                        fontSize: "30px",
                                        color: "#1270c8",
                                    }}
                                />
                                {detail?.readingTime} Min Read
                            </Typography>
                        </Stack>
                    </Stack>
                    <Typography
                        className={`markdownBlog ${classes.mainContent}`}
                        // className="markdownBlog"
                        dangerouslySetInnerHTML={{ __html: detail?.description }}
                        sx={{ marginTop: "40px" }}
                    />
                    <PopUp button={false} />

                </Box>
            </Container>
            {/* {similar.length && ( */}
            <Stack
                direction="column"
                justifyContent="center"
                alignItems={"center"}
                sx={{
                    backgroundImage:
                        "linear-gradient(180deg, #0B0D23 83.33%, #242242 100%)",
                    marginTop: "50px",
                }}
            >
                <Typography variant="h4" sx={{ margin: "30px" }}>
                    SIMILAR BLOGS
                </Typography>

                <Container
                    maxWidth="lg"
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    {/* <div style={{ display: "flex", gap: "40px", flexWrap: "wrap", justifyContent: "left", textAlign: "left" }}> */}
                    <Grid container spacing={5} justifyContent={"flex-start"} alignItems="center">

                        {similar.map((e) => (
                            <Grid item xs={12} sm={6} md={4} >

                                <Card
                                    sx={{
                                        marginTop: "20px",
                                        width: "100%",
                                        // marginRight: "10px",
                                        // margin: "0 auto",
                                    }}
                                    onClick={() => navigate(`/blogs/blogdetail?id=${e._id}`)}
                                >
                                    <CardMedia
                                        component="img"
                                        image={e.images[0]}
                                        alt="card img"
                                        sx={{ cursor: "pointer", }}
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            sx={{
                                                cursor: "pointer",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                width: "100%",
                                                color: "black",
                                            }}
                                        >
                                            {e?.title}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" sx={{ color: "black" }}> <CalendarMonth fontSize="small" />&nbsp;
                                            {moment(e.createdAt).fromNow()}
                                        </Button>
                                        <Button size="small" sx={{ color: "black" }}><AccessTime fontSize="small" /> &nbsp;
                                            {e.readingTime} min Read
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>

                        ))}
                    </Grid>
                    {/* </div> */}

                    <div
                        className="pagination"
                        style={{ paddingBottom: '50px', marginTop: '3rem', ...styles.pagination }}
                    >
                        <Pagination
                            count={maxRecords}
                            page={currentPage}
                            onChange={(e, newVal) => setCurrentPage(newVal)}
                            color="primary"
                            sx={{
                                '& .MuiPaginationItem-root': {
                                    ...styles.paginationButton,
                                },
                                '& .Mui-selected': {
                                    ...styles.activeButton,
                                },
                            }}
                        />
                    </div>

                    {/* <BlogPagination
                            totalPosts={similar.length}
                            postsPerPage={postsPerPage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        /> */}
                </Container>
            </Stack>
            {/* )
            } */}
            <ContactForm title="Get a quote" />

        </div >

    );
};

export default BlogDetail;
